import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"  
import Img from 'gatsby-image' 
import Carousel from "react-bootstrap/Carousel"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
/*
<Carousel interval={2000}  style={{width:"inherit"}}>
      {data.allFile.edges.map(edge => {
        return (          
        <Carousel.Item>
        <Img fluid={edge.node.childImageSharp.fluid}  />
        </Carousel.Item> 
          
      )})}
      </Carousel>

      ::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
} 
::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 10px;
} 
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}
      */ 
const Gallery=({data}) =>(
  
    <Layout >
      <SEO title="Gallery" />
      <div >
      <Row style={{flexWrap:"nowrap", overflowX:"scroll", }} id="forscroll">
      {data.allFile.edges.map(edge => {
        return (          
          <Col xs={8}>
        <Img fluid={edge.node.childImageSharp.fluid} style={{height:"350px", marginTop:"20px",boxShadow: "rgb(154, 119, 70) 6px 11px 17px 3px"}}  />
     
        </Col>
          
      )})}
  </Row>

</div>
      
       </Layout>
  )
  export default Gallery

  export const query = graphql`
    query assetsPhotosAndAssetsPhotos {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, dir: {regex: "/src/images/emp/"}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(   quality: 100) {
                ...GatsbyImageSharpFluid 
              }
            }
          }
        }
      }
    }
  `
 

